<template>
  <!-- Table -->
  <div
    class="bg-white shadow overflow-y-auto overflow-x-hidden sm:rounded-md max-h-screen"
  >
    <ul role="list" class="divide-y divide-gray-200">
      <li
        v-for="(ticket, ticketIdx) in this.filteredTickets()"
        :key="ticket.id"
      >
        <a
          href="javascript:void(0)"
          :class="[
            this.ticketId == ticket.id ? 'bg-gray-200' : 'bg-white',
            'block hover:bg-gray-50',
          ]"
          @click="goToDetail(ticket.id, ticket.status_id, ticket.ticketable_id)"
        >
          <div class="px-4 py-4 sm:px-6">
            <div class="flex items-center justify-between">
              <div class="inline-flex truncate">
                <p class="text-sm font-medium body__table--body__text truncate">
                  {{ ticket.subject }}
                </p>
                <div
                  class="mt-2 ml-1.5 flex items-center text-xs text-gray-500 sm:mt-0"
                >
                  <CalendarIcon
                    class="flex-shrink-0 mr-0.5 h-4 w-4 text-gray-400"
                    aria-hidden="true"
                  />
                  <p class="text-xs mt-0.5">
                    <time>{{ formatDate(ticket.created_at) }}</time>
                  </p>
                </div>
              </div>

              <div class="ml-2 flex-shrink-0 flex ">
                <p
                  @click="this.togglePriority(ticketIdx)"
                  :class="[
                    ticket.status_id == 1
                      ? `encom_ouvert_ticket`
                      : ticket.status_id == 2
                      ? `encom_en_traitement_ticket`
                      : ticket.status_id == 3
                      ? `encom_pink_bg`
                      : `encom_closed_ticket`,
                    'text-white px-2 inline-flex text-xs font-semibold leading-5 uppercase rounded-full',
                  ]"
                >
                  <svg
                    :class="[
                      ticket.priority_id == 1
                        ? ' mr-1.5 mt-1 h-3 w-3 text-blue-300'
                        : ticket.priority_id == 2
                        ? 'mr-1.5 mt-1 h-3 w-3 text-green-400'
                        : ticket.priority_id == 3
                        ? 'mr-1.5 mt-1 h-3 w-3 text-yellow-500'
                        : 'mr-1.5 mt-1 h-3 w-3 text-red-600',
                    ]"
                    fill="currentColor"
                    viewBox="0 0 8 8"
                  >
                    <circle cx="4" cy="4" r="3" />
                  </svg>
                  {{ this.checkStatus(ticket.status_id) }}
                </p>
              </div>
            </div>
            <div
              v-if="
                String(this.showIdx) === String(ticketIdx) && this.showPriority
              "
              class="flex justify-end mt-1"
            >
              <p
                :class="[
                  ticket.priority_id == 1
                    ? `inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800`
                    : ticket.priority_id == 2
                    ? `inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800`
                    : ticket.priority_id == 3
                    ? `inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800`
                    : `inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800`,
                ]"
              >
                {{ checkPriority(ticket.priority_id) }}
              </p>
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";

import {
  CalendarIcon,
  LocationMarkerIcon,
  UsersIcon,
} from "@heroicons/vue/solid";

const account =
  localStorage.getItem("account") === ""
    ? this.$store.state.user.activeAccount
    : localStorage.getItem("account");

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default {
  props: [
    "ticketId",
    "search",
    "searchStatus",
    "selectedPeriod",
    "ticketableId",
  ],
  components: {
    CalendarIcon,
    LocationMarkerIcon,
    UsersIcon,
  },
  data() {
    return {
      account,
      months,
      status: [],
      tickets: [],
      updateTicket: false,
      activeFilter: "",
      priority: [
        { id: 1, name: "Basse" },
        { id: 2, name: "Normale" },
        { id: 3, name: "Haute" },
        { id: 4, name: "Critique" },
      ],
      showIdx: "",
      showPriority: false,
    };
  },
  methods: {
    async getTickets() {
      if (this.selectedPeriod === "all" || !this.selectedPeriod) {
        try {
          const res = await axios.get(
            `${this.$cookie.getCookie(
              "API"
            )}/api/v1/tickets?filter[ticketable_id]=${this.ticketableId}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          this.isLoading = false;
          this.tickets = res.data;
        } catch (error) {
          console.error(error);
          if (error.response.status == 401) {
            localStorage.removeItem("token");
            this.$store.dispatch("token", null);
            this.$router.push("/login");
          }
        }
      } else if (this.selectedPeriod === "day") {
        try {
          const res = await axios.get(
            `${this.$cookie.getCookie("API")}/api/v1/ticketsDaily`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          this.isLoading = false;
          this.tickets = res.data;
        } catch (error) {
          console.error(error);
          if (error.response.status == 401) {
            localStorage.removeItem("token");
            this.$store.dispatch("token", null);
            this.$router.push("/login");
          }
        }
      } else if (this.selectedPeriod === "week") {
        try {
          const res = await axios.get(
            `${this.$cookie.getCookie("API")}/api/v1/ticketsWeekly`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          this.isLoading = false;
          this.tickets = res.data;
        } catch (error) {
          console.error(error);
          if (error.response.status == 401) {
            localStorage.removeItem("token");
            this.$store.dispatch("token", null);
            this.$router.push("/login");
          }
        }
      } else if (this.selectedPeriod === "unassigned") {
        try {
          const res = await axios.get(
            `${this.$cookie.getCookie("API")}/api/v1/tickets`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          this.tickets = res.data.filter((ticket) => {
            return !ticket.assignee_id;
          });
          this.isLoading = false;
        } catch (error) {
          console.error(error);
          if (error.response.status == 401) {
            localStorage.removeItem("token");
            this.$store.dispatch("token", null);
            this.$router.push("/login");
          }
        }
      } else if (this.selectedPeriod === "my") {
        try {
          const res = await axios.get(
            `${this.$cookie.getCookie(
              "API"
            )}/api/v1/tickets?filter[assignee_id]=${localStorage.getItem(
              "userId"
            )}`
          );
          this.tickets = res.data;
          this.isLoading = false;
        } catch (error) {
          this.errorHandling(error);
        }
      }
    },
    filteredTickets() {
      return this.tickets.filter((ticket) => {
        if (this.searchStatus.length > 0) {
          let status = this.checkStatus(ticket.status_id);
          if (this.search) {
            return (
              ticket.subject
                .toLowerCase()
                .includes(this.search.toLowerCase()) ||
              this.formatDate(ticket.created_at)
                .toLowerCase()
                .includes(this.search.toLowerCase()) ||
              this.formatDate(ticket.updated_at)
                .toLowerCase()
                .includes(this.search.toLowerCase())
            );
          }
          return this.searchStatus.includes(status);
        } else if (this.search) {
          return (
            ticket.subject.toLowerCase().includes(this.search.toLowerCase()) ||
            this.formatDate(ticket.created_at)
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            this.formatDate(ticket.updated_at)
              .toLowerCase()
              .includes(this.search.toLowerCase())
          );
        }
        return ticket;
      });
    },
    goToDetail(id, status, ticketableId) {
      let statusName = this.checkStatus(status);
      this.$router.push(`/tickets/${id}/${ticketableId}`);
      this.$emit("updateTicketDetail", id);
      this.$forceUpdate();
    },
    formatDate(date) {
      const dateCreated = new Date(date);
      const formatedDate =
        ("0" + dateCreated.getDate()).slice(-2) +
        "/" +
        ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
        "/" +
        dateCreated.getFullYear();

      return formatedDate;
    },

    async getStatus() {
      const res = await axios.get(
        `${this.$cookie.getCookie("API")}/api/v1/statuses`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      this.status = res.data;
    },
    checkStatus(id) {
      if (this.status) {
        for (let index = 0; index < this.status.length; index++) {
          if (this.status[index].id == id) {
            return this.status[index].name;
          }
        }
      }
    },
    checkPriority(id) {
      if (this.priority) {
        for (let index = 0; index < this.priority.length; index++) {
          if (this.priority[index].id == id) {
            return this.priority[index].name;
          }
        }
      }
    },
    togglePriority(idx) {
      // check if the index of the item on the list is different from the one that is active, and if the chart is opened
      // when clicked - if the index is not the one active - it will open the one that was clicked and close the one that was active
      if (this.showIdx !== "" && idx != this.showIdx && this.showPriority) {
        this.showIdx = idx;
        this.showPriority = true;
      } else {
        this.showPriority = !this.showPriority;
        this.showIdx = idx;
      }
    },
  },
  mounted() {
    this.getTickets();
    this.getStatus();
  },
  watch: {
    selectedPeriod: function(val) {
      this.getTickets();
    },
  },
};
</script>

<style></style>
