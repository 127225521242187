<template>
  <!-- Card Header -->
  <TicketDetailHeader
    :ticketId="id"
    :ticketContacts="contacts"
    :customerData="[]"
    :contactId="contactId"
    :coworkers="coworkers"
    v-model:searchContact="searchContact"
    @getContacts="getTicket($event)"
    @is-search-customer="this.headerCollapse($event)"
    @activeBanner="(activeBanner = true), getTicketDetail(), (key = !key)"
    @successMsg="successMsg = $event"
    @success="isSuccess = $event"
  />
  <SuccessBanner
    :key="key"
    :msg="successMsg"
    :active="activeBanner"
    @closeBanner="activeBanner = false"
    :isSuccess="isSuccess"
  />
  <div
    class="grid grid-cols-12 flex-1 relative z-0 flex overflow-x-hidden overflow-y-auto"
  >
    <main
      :class="[
        isListShow
          ? 'xl:col-span-9 col-span-12'
          : 'mx-5 col-span-12 flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last',
      ]"
    >
      <div class="overflow-hidden chat_background xl:rounded-t-lg">
        <div class="grid grid-cols-12 gap-4 pr-10 pb-10">
          <div v-show="!isListShow" class="col-span-1 h-20 w-10 pt-2">
            <button
              @click="this.isListShow = !this.isListShow"
              type="button"
              title="Toggle tickets list"
              class="mt-1 ml-2 rounded inline-flex justify-center py-2 px-3 border border-gray-300 shadow-sm text-sm font-medium bg-gray-100 text-gray-700 hover:bg-gray-50"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
          <div
            :class="[
              isListShow ? 'col-span-12 ml-5' : 'col-span-11 -ml-10',
              'mt-5 pt-2 px-5 pb-5 bg-gray-100 rounded-md',
            ]"
          >
            <h4
              :class="[
                this.status === 'Ouvert'
                  ? 'encom_ouvert_ticket_text'
                  : this.status === 'En cours de traitement'
                  ? 'encom_yellow_text'
                  : this.status === 'En attente'
                  ? 'text-red-600'
                  : this.status === 'Traité dans un autre ticket'
                  ? 'text-red-600'
                  : '',
                `text-lg leading-6 font-medium sm:ml-0 pb-1`,
              ]"
            >
              #{{ this.status }}
            </h4>
            <h3>
              <span style="font-weight: bold; color: black">{{
                ticket.subject
              }}</span>
            </h3>
            <br />
            <!-- {{ ticket.body }} -->
            <span v-html="ticket.body"></span>
          </div>
        </div>
        <!-- {{ ticket.replies }} -->
        <!-- Responses -->

        <div v-for="reply in ticketReplies" :key="reply.id">
          <div class="grid grid-col-10 px-2 py-2 flex justify-between">
            <div class="xl:pl-10" v-if="reply.origin !== 'admin'">
              <div class="flex justify-between">
                <p class="text-gray-500 text-xs text-left pl-5">
                  {{ this.formatDate(reply.created_at) }}
                </p>
              </div>
              <div
                v-if="reply.origin !== 'admin'"
                class="bg-white text-left w-60 lg:w-auto lg:max-w-2xl shadow-md talk-bubble-admin talk-bubble tri-right left-in round hover:shadow-xl"
              >
                <div>
                  <div class="px-4 py-5 sm:p-6 text-sm break-words talktext">
                    <p>
                      <span v-html="reply.body"></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-end-10 xl:pr-10">
              <div
                class="flex justify-between mt-2 mx-2"
                v-if="reply.customerVisible === 0"
              >
                <p class="text-gray-500 text-xs text-left italic font-medium">
                  This content is an internal reply
                </p>
              </div>
              <div
                v-if="reply.origin === 'admin'"
                :class="[
                  reply.customerVisible === 0
                    ? 'internal-reply right-in internal-tri-right'
                    : 'right-in tri-right',
                  'text-right w-60 lg:w-auto lg:max-w-2xl shadow-md talk-bubble round hover:shadow-xl',
                ]"
              >
                <div>
                  <div
                    class="flex justify-between mt-2 mx-5"
                    v-if="reply.origin === 'admin'"
                  >
                    <p class="text-gray-500 text-xs text-left mr-2">
                      {{ this.formatDate(reply.created_at) }}
                    </p>
                    <p class="text-gray-500 text-xs text-left">
                      {{ getUserReply(reply.id) }}
                    </p>
                  </div>
                  <div class="px-4 py-5 sm:p-6 text-sm break-words talktext">
                    <p>
                      <span v-html="reply.body"></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-gray-100 overflow-hidden border-b">
        <form
          class="space-y-2"
          @submit.prevent="reply($t('ticketTable.successMsg'))"
          method="POST"
        >
          <div class="mt-3">
            <label
              for="country"
              class="block text-sm font-medium text-gray-700"
            >
              {{ $t("ticket.templateAvailable") }}
            </label>
            <select
              @change="putTemplate($event)"
              id="status"
              name="status"
              class="mt-0 block w-full text-base border-gray-300 encom_primary_border_focus sm:text-sm"
            >
              <option value="">Select template</option>
              <option
                v-for="template in replyTemplates"
                :value="template.name"
                :selected="
                  this.defaultTemplate.length
                    ? template.name === this.defaultTemplate[0].name
                    : false
                "
                :key="template.id"
                >{{ template.name }}</option
              >
            </select>
          </div>
          <div class="space-y-8">
            <div>
              <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-10">
                <div class="sm:col-span-8">
                  <div id="app">
                    <ckeditor
                      :editor="editor"
                      v-model="editorData"
                      :config="editorConfig"
                    ></ckeditor>
                  </div>
                </div>
                <!-- Checkboxes reply ticket -->
                <div
                  :class="[isListShow ? 'h-1/2' : '', ' flex sm:col-span-2']"
                >
                  <fieldset
                    class="space-y-4 px-3 border rounded-md border-gray-200 bg-white"
                  >
                    <legend class="sr-only">Notifications</legend>
                    <div class="relative flex items-start mb-4">
                      <div class="flex items-center h-5">
                        <input
                          v-model="sendTicketData.sendToContacts"
                          id="sendToContacts"
                          aria-describedby="send-to-contacts"
                          name="sendToContacts"
                          type="checkbox"
                          class=" h-4 w-4 primary_text border-gray-300 rounded"
                        />
                      </div>
                      <div class="ml-3 text-sm">
                        <label
                          for="sendToContacts"
                          class="font-medium text-gray-700"
                          >{{ $t("ticket.sendToContacts") }}</label
                        >
                        <p
                          v-show="!isListShow"
                          id="contacts-description"
                          class="text-gray-500"
                        >
                          {{ $t("ticket.sendToContactsDescription") }}.
                        </p>
                      </div>
                    </div>
                    <div class="relative flex items-start mb-4">
                      <div class="flex items-center h-5">
                        <input
                          v-model="sendTicketData.sendToAssignee"
                          id="sendToAssignee"
                          aria-describedby="send-ticket-assignee"
                          name="sendToAssignee"
                          type="checkbox"
                          class=" h-4 w-4 primary_text border-gray-300 rounded"
                        />
                      </div>
                      <div class="ml-3 text-sm">
                        <label
                          for="sendToAssignee"
                          class="font-medium text-gray-700"
                          >{{ $t("ticket.sendToAssignee") }}</label
                        >
                        <p
                          v-show="!isListShow"
                          id="assignee-description"
                          class="text-gray-500"
                        >
                          {{ $t("ticket.sendToAssigneeDescription") }}.
                        </p>
                      </div>
                    </div>
                    <div class="relative flex items-start">
                      <div class="flex items-center h-5">
                        <input
                          v-model="sendTicketData.close"
                          id="close"
                          aria-describedby="close-ticket"
                          name="close"
                          type="checkbox"
                          class="h-4 w-4 primary_text border-gray-300 rounded"
                        />
                      </div>
                      <div class="ml-3 text-sm">
                        <label for="close" class="font-medium text-gray-700">{{
                          $t("ticket.closeTicket")
                        }}</label>
                        <p
                          v-show="!isListShow"
                          id="close-description"
                          class="text-gray-500"
                        >
                          {{ $t("ticket.closeTicketDescription") }}.
                        </p>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>

          <div class="flex justify-between pt-0 pb-2 pr-2">
            <button
              type="submit"
              class="mt-1 rounded inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium body__button text-white"
            >
              {{ $t("ticket.send") }}
            </button>
          </div>
        </form>
      </div>
    </main>

    <aside
      v-show="isListShow"
      class="h-full mx-2 col-span-3 hidden relative xl:order-first xl:flex xl:flex-col flex-shrink-0"
    >
      <div
        :class="
          `bg-gray-100 px-4 py-2 border-b border-gray-200 sm:px-6 grid flex justify-between inline-flex`
        "
      >
        <div class="-ml-8 -mt-4 row-start-1">
          <div class="ml-4 mt-8 inline-flex">
            <div class="inline-flex">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                <span style="font-weight: bold; color: black"
                  >{{ ticket.subject }} [#{{ ticket.id }}]</span
                >
              </h3>
            </div>
            <br />
          </div>
          <Menu as="div" class="relative inline-block text-left mr-2 pl-2">
            <MenuButton
              type="button"
              class="-ml-px relative inline-flex items-center space-x-2 px-2 py-1 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-gray-50 hover:bg-gray-100"
            >
              <FilterIcon class="h-4 w-4 text-gray-400" aria-hidden="true" />
            </MenuButton>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="z-10 origin-top-left absolute mt-2 w-auto shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none capitalize"
              >
                <div class="py-1">
                  <MenuItem
                    v-for="period in ticketPeriod"
                    :key="period"
                    v-slot="{ active }"
                  >
                    <a
                      :class="[
                        status.current
                          ? 'font-medium text-gray-900'
                          : 'text-gray-500',
                        active ? 'bg-gray-100' : '',
                        period === selectedPeriod ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm cursor-pointer',
                      ]"
                      @click="selectedPeriod = period"
                    >
                      <div class="flex">
                        {{ period }}
                      </div>
                    </a>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
        <div class="mt-3 row-start-1">
          <button
            @click="this.isListShow = false"
            title="Hide tickets list"
            class="rounded inline-flex justify-center py-2 px-3 border border-gray-300 shadow-sm text-sm font-medium bg-white text-gray-700 hover:bg-gray-50"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M3 3a1 1 0 011 1v12a1 1 0 11-2 0V4a1 1 0 011-1zm7.707 3.293a1 1 0 010 1.414L9.414 9H17a1 1 0 110 2H9.414l1.293 1.293a1 1 0 01-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
      <div class="py-2 mr-1">
        <div class="mt-1 flex rounded-md shadow-sm">
          <div class="relative flex items-stretch flex-grow focus-within:z-10">
            <div
              class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
            >
              <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              type="text"
              name="email"
              id="email"
              class="focus:encom_border_input block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300"
              placeholder="Search"
              v-model="this.search"
            />
          </div>
          <Menu as="div" class="relative inline-block text-left">
            <MenuButton
              type="button"
              class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100"
            >
              <FilterIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </MenuButton>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="z-10 origin-top-left absolute mt-2 w-60 shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <div class="py-1">
                  <MenuItem
                    v-for="status in statusList"
                    :key="status.id"
                    v-slot="{ active }"
                  >
                    <a
                      :class="[
                        status.current
                          ? 'font-medium text-gray-900'
                          : 'text-gray-500',
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm cursor-pointer',
                      ]"
                      @click="this.pushSearchStatus(status.name)"
                    >
                      <div class="flex justify-between">
                        {{ status.name }}
                        <svg
                          v-show="this.searchStatus.includes(status.name)"
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                    </a>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
      <!-- Start secondary column (hidden on smaller screens) -->
      <TicketListDetail
        :ticketableId="ticketableId"
        :selectedPeriod="selectedPeriod"
        :searchStatus="searchStatus"
        :ticketId="this.id"
        :search="this.search"
        @updateTicketDetail="this.getTicketDetail($event)"
      />

      <!-- End secondary column -->
    </aside>
  </div>
  <loading
    v-model:active="isLoading"
    :can-cancel="false"
    :on-cancel="onCancel"
    :is-full-page="fullPage"
  />

  <ConfirmationDialog
    v-if="this.showMessage"
    :messageToShow="this.messageToShow"
    :subMessageToShow="this.subMessageToShow"
  />
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

import SuccessBanner from "../../components/SuccessBanner.vue";
import TicketListDetail from "../../components/tickets/TicketListDetail.vue";
import SearchFilter from "../../components/SearchFilter.vue";
import ConfirmationDialog from "../../components/ConfirmationDialog.vue";
import TicketDetailHeader from "../../components/tickets/TicketDetailHeader.vue";

import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";

import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";

import ImageInsertPlugin from "@ckeditor/ckeditor5-image/src/imageinsert";
import ImagePlugin from "@ckeditor/ckeditor5-image/src/image";
import ImageToolbarPlugin from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import AutoImagePlugin from "@ckeditor/ckeditor5-image/src/autoimage";
import ImageResizePlugin from "@ckeditor/ckeditor5-image/src/imageresize";
import ImageStylePlugin from "@ckeditor/ckeditor5-image/src/imagestyle";

// import IndentPlugin from "@ckeditor/ckeditor5-indent/src/indent";
// import IndentBlockPlugin from "@ckeditor/ckeditor5-indent/src/indentblock";
// import ListStylePlugin from "@ckeditor/ckeditor5-list/src/liststyle";

import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { FilterIcon, SearchIcon } from "@heroicons/vue/solid";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "TicketDetail",
  props: ["account", "id", "ticketableId"],
  components: {
    SuccessBanner,
    TicketListDetail,
    SearchFilter,
    FilterIcon,
    SearchIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ConfirmationDialog,
    Loading,
    TicketDetailHeader,
  },
  data() {
    return {
      isLoading: true,
      isListShow: false,
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        fillEmptyBlocks: false,
        tabSpaces: 0,
        placeholder: "Write your reply here...",
        plugins: [
          EssentialsPlugin,
          BoldPlugin,
          ItalicPlugin,
          LinkPlugin,
          ParagraphPlugin,
          ImageInsertPlugin,
          SimpleUploadAdapter,
          ImagePlugin,
          ImageResizePlugin,
          ImageStylePlugin,
          ImageToolbarPlugin,
          AutoImagePlugin,
        ],
        simpleUpload: {
          // The URL that the images are uploaded to.
          uploadUrl: `${this.$cookie.getCookie("API")}/api/v1/pictures`,

          // Enable the XMLHttpRequest.withCredentials property.
          withCredentials: false,

          // Headers sent along with the XMLHttpRequest to the upload server.
          headers: {
            "X-CSRF-TOKEN": "CSRF-Token",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
        toolbar: {
          items: [
            "heading",
            "bold",
            "italic",
            "|",
            "link",
            "|",
            "undo",
            "redo",
            "|",
            "indent",
            "outdent",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "insertImage",
          ],
        },
        indentBlock: {
          offset: 1,
          unit: "em",
        },
        language: "en",
        image: {
          toolbar: [
            "imageTextAlternative",
            "imageStyle:inline",
            "imageStyle:block",
            "imageStyle:side",
          ],
        },
        licenseKey: "",
      },
      ticket: {},
      ticketReplies: [],
      ticketUser: {},
      showMessage: false,
      subMessageToShow: "",
      messageToShow: "",
      search: "",
      searchStatus: [],
      statusList: [],
      selectedPeriod: "",
      ticketPeriod: ["day", "week", "unassigned", "my", "all"],
      sendTicketData: {
        body: "",
        close: false,
        sendToAssignee: false,
        sendToContacts: true,
      },
      replyTemplates: [],
      defaultTemplate: [],
      contacts: [],
      contactId: "",
      coworkers: [],
      searchContact: "",
      userName: "",
      isSearchCustomer: null,
      status: undefined,
      successMsg: "",
      activeBanner: false,
      isSuccess: true,
      key: false,
    };
  },
  methods: {
    getUserReply(id) {
      for (let index = 0; index < this.ticketReplies.length; index++) {
        if (this.ticketReplies[index].id == id) {
          let name = this.ticketReplies[index].user_id;
          return name;
        }
      }
    },
    getTicket(id) {
      this.getStatus().then(async (res) => {
        if (id) {
          try {
            const res = await axios.get(
              `${this.$cookie.getCookie("API")}/api/v1/tickets/${id}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            );
            this.getStatusName(res.data.status_id);
            this.ticket = res.data;
            this.ticketReplies = res.data.replies;
            this.contacts = res.data.contacts;
            console.log("res.data.contacts", res.data.contacts);
            if (res.data.contacts.length) {
              this.getContactTeam(res.data.contacts[0].id);
            }
            this.isLoading = false;
            this.$forceUpdate();
          } catch (error) {
            this.isLoading = false;
            console.error(error);
            this.errorHandling(error);
          }
        }
      });
    },
    async getTicketReplies(id) {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/tickets/${id}`
        );
        if (res) {
          this.ticketReplies = res.data.replies;
          this.isLoading = false;
          this.$forceUpdate();
        }
      } catch (error) {
        this.isLoading = false;
        this.errorHandling(error);
      }
    },
    async getUser(id) {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/users/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        this.userName = res.data.name;
      } catch (error) {
        console.error(error);
        this.errorHandling(error);
      }
    },
    async getContactTeam(id) {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/contacts/${id}/coworkers`
        );
        console.log(res.data);
        this.coworkers = res.data;
      } catch (error) {
        console.error(error);
        this.errorHandling(error);
      }
    },
    filterContactTeam() {
      return this.coworkers.filter((worker) => {
        if (worker.firstName && worker.lastName) {
          return (
            worker.firstName
              .toLowerCase()
              .includes(this.searchContact.toLowerCase()) ||
            worker.lastName
              .toLowerCase()
              .includes(this.searchContact.toLowerCase())
          );
        } else if (!worker.firstName && worker.lastName) {
          return worker.lastName
            .toLowerCase()
            .includes(this.searchContact.toLowerCase());
        } else if (worker.firstName && !worker.lastName) {
          worker.firstName
            .toLowerCase()
            .includes(this.searchContact.toLowerCase());
        }
      });
    },
    async getTicketTemplates() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/replyTemplates`
        );
        this.replyTemplates = res.data;
        this.defaultTemplate = res.data.filter((template) => {
          return template.default;
        });
        if (this.defaultTemplate.length) {
          this.editorData = this.defaultTemplate[0].body;
        } else {
          this.editorData = "";
        }
      } catch (error) {
        console.error(error);
        this.errorHandling(error);
      }
    },
    putTemplate(event) {
      if (event.target.value) {
        let status = this.replyTemplates.filter((template) => {
          return template.name === event.target.value;
        });
        this.editorData = status[0].body;
      } else {
        this.editorData = "";
      }
    },
    getStatusName(id) {
      this.statusList.filter((status) => {
        if (status.id == id) {
          console.log(status.name);
          this.status = status.name;
        }
      });
    },
    async getStatus() {
      const res = await axios.get(
        `${this.$cookie.getCookie("API")}/api/v1/statuses`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      this.statusList = res.data;
    },
    getTicketDetail(event) {
      this.getTicket(event);
      this.$forceUpdate();
    },
    pushSearchStatus(status) {
      if (!this.searchStatus.includes(status)) {
        this.searchStatus.push(status);
      } else {
        let index = this.searchStatus.indexOf(status);
        if (index > -1) {
          this.searchStatus.splice(index, 1);
        }
      }
    },
    async reply(successMsg) {
      if (this.editorData === "") {
        console.log("oisahdoiadjgoasdirg");
        this.activeBanner = true;
        this.isSuccess = false;
        this.successMsg = "Please write something before replying.";
        this.key = !this.key;
        this.getTicketDetail();
      } else {
        let str = String(this.editorData);
        let noSpace = str.split("&nbsp;").join("");

        this.sendTicketData.body = noSpace;

        axios
          .post(
            `${this.$cookie.getCookie("API")}/api/v1/replies?ticketId=${
              this.id
            }`,
            this.sendTicketData
          )
          .then((res) => {
            if (res) {
              window.scrollTo(0, document.body.scrollHeight);
              this.getTicketReplies(this.id);
              this.editorData = "";

              this.activeBanner = true;
              this.isSuccess = true;
              this.successMsg = successMsg;
              this.key = !this.key;
              this.getTicketDetail();
            } else {
              window.alert("Something went wrong.");
            }
          });
      }
    },
    sendMessage(msg, subMsg) {
      this.showMessage = true;
      this.messageToShow = msg;
      this.subMessageToShow = subMsg;
    },
    formatDate(date) {
      const dateCreated = new Date(date);
      const formatedDate =
        ("0" + dateCreated.getDate()).slice(-2) +
        "/" +
        ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
        "/" +
        dateCreated.getFullYear() +
        " at " +
        dateCreated.getHours() +
        ":" +
        ("0" + dateCreated.getMinutes()).slice(-2);

      return formatedDate;
    },
    headerCollapse(event) {
      this.isSearchCustomer = event;
    },
  },
  mounted() {
    this.getTicket(this.id);
    this.getTicketTemplates();
  },
  computed: {
    ...mapGetters(["token", "userId"]),
  },
};
</script>

<style>
.ck {
  height: 20vh;
}

.max_screen_height {
  height: 100vh;
}

.chat_background {
  background: linear-gradient(
      rgba(229, 221, 213, 0.877),
      rgba(229, 221, 213, 0.87)
    ),
    url("../../assets/background/ticket_background.jpg") center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: contain;
  z-index: 0;
}
.subject_background {
  background: rgba(226, 221, 217);
}
.chat_box_right {
  border-top-left-radius: 2rem;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 2rem;
}
.chat_box_left {
  border-top-right-radius: 2rem;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 2rem;
}

/* Chat styling */

.talk-bubble {
  display: inline-block;
  position: relative;
  width: auto;
  min-width: 200px;
  max-width: 700px;
  min-height: 100px;
  height: auto;
  background-color: #dcf8c6;
}
.talk-bubble-admin {
  display: inline-block;
  position: relative;
  width: auto;
  min-width: 200px;
  max-width: 700px;
  min-height: 100px;
  height: auto;
  background-color: #ffffff;
}
.round {
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
}

/* Right triangle, left side slightly down */
.tri-right.border.left-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -40px;
  right: auto;
  top: 20px;
  bottom: auto;
  border: 20px solid;
  border-color: #666 #666 transparent transparent;
}
.tri-right.left-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -20px;
  right: auto;
  top: 33px;
  bottom: auto;
  border: 12px solid;
  border-color: #ffffff #ffffff transparent transparent;
}

/*
	left: -8px;
  right: auto;
  top: auto;
	bottom: -40px;
	border: 32px solid;
	border-color: transparent transparent transparent #666;
	left: 0px;
  right: auto;
  top: auto;
	bottom: -20px;
	border: 22px solid;
	border-color: transparent transparent transparent lightyellow;

/* Right triangle, right side slightly down*/
.tri-right.border.right-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -40px;
  top: 20px;
  bottom: auto;
  border: 20px solid;
  border-color: #666 transparent transparent #666;
}
.tri-right.right-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -20px;
  top: 33px;
  bottom: auto;
  border: 12px solid;
  border-color: #dcf8c6 transparent transparent #dcf8c6;
}
.internal-reply {
  background-color: #cde7b9;
}
.internal-tri-right.border.right-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -40px;
  top: 20px;
  bottom: auto;
  border: 20px solid;
  border-color: #666 transparent transparent #666;
}
.internal-tri-right.right-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -20px;
  top: 33px;
  bottom: auto;
  border: 12px solid;
  border-color: #cde7b9 transparent transparent #cde7b9;
}
/* talk bubble contents */
.talktext {
  padding: 1em;
  text-align: left;
  line-height: 1.5em;
}
.talktext p {
  /* remove webkit p margins */
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
}

@media only screen and (max-width: 668px) {
  .talk-bubble {
    display: inline-block;
    position: relative;
    width: auto;
    min-width: 150px;
    max-width: 200px;
    margin-right: 15px;
    min-height: 70px;
    height: auto;
    background-color: #dcf8c6;
  }
  .talk-bubble-admin {
    display: inline-block;
    position: relative;
    width: auto;
    min-width: 150px;
    max-width: 200px;
    margin-left: 15px;
    min-height: 70px;
    height: auto;
    background-color: #ffffff;
  }
}
</style>
